import React from 'react';
import { mobile } from '../../helpers/OperationsHelper';
import { getProductTypeFilteList, getProductTypevariationFilteList } from '../../helpers/ServicesHelper';

class CoreFilter extends React.Component {
  //on change of filter
  async changeFilterState(value, name, type) { 
    if (mobile == true) {
      var productTypeFilters = this.props.filters;
    }
    else {
      var productTypeFilters = this.props.productTypeFilters;
      // console.log(productTypeFilters);
    }
    productTypeFilters.map((productTypeFilter, index) => {
      if (type == 'range' && productTypeFilter.type == 'range' && productTypeFilter.name == name) {
        productTypeFilters[index].selected_values['min_value'] = value[0];
        productTypeFilters[index].selected_values['max_value'] = value[1];
      }
      else if (type == 'multiselect' &&
        productTypeFilter.type == 'multiselect' &&
        productTypeFilter.name == name) {
        if (productTypeFilter.selected_values.indexOf(encodeURIComponent(value)) < 0)
          productTypeFilters[index].selected_values.push(encodeURIComponent(value));
        else {
          var removeItemIndex = productTypeFilters[index].selected_values.indexOf(encodeURIComponent(value));
          productTypeFilters[index].selected_values.splice(removeItemIndex, 1);
        }
      } else if (type == 'text' &&
        productTypeFilter.type == 'text' &&
        productTypeFilter.name == name) {
        productTypeFilters[index].selected_values = value;
      }
    })
    await this.setState({ 'productTypeFilters': productTypeFilters });
    this.props.setFilters(productTypeFilters);
    if (mobile == false)
      this.applyFilter();
  }

  //on change of variation filter
  async changeVariationFilterState(value, name, type) {
    if (mobile == true)
      var productTypeVariationFilter = this.props.variationfilterData;
    else
      var productTypeVariationFilter = this.props.productTypevariationFilters;
      productTypeVariationFilter.map((productTypeFilter, index) => {
      if (type == 'multiselect' &&
        productTypeFilter.type == 'multiselect' &&
        productTypeFilter.name == name) {
        if (productTypeFilter.selected_values.indexOf(value) < 0)
          productTypeVariationFilter[index].selected_values.push(value);
        else {
          var removeItemIndex = productTypeVariationFilter[index].selected_values.indexOf(value);
          productTypeVariationFilter[index].selected_values.splice(removeItemIndex, 1);
        }
      }
    })
    await this.setState({ 'productTypeVariationFilter': productTypeVariationFilter });
    this.props.setVariationFilters(productTypeVariationFilter);
    if (mobile == false)
      this.applyFilter();
  }

  // for apply filter no api call
  applyFilter = async () => {
    var filters = this.props.filters;
    var variationFilters = this.props.variationfilterData;

    if (typeof this.props.productTypeFilters != 'undefined' && this.props.productTypeFilters.length > 0) {
      this.props.productTypeFilters.map((productTypeFilter, index) => {
        if (productTypeFilter.selected_values != "") {
          if (productTypeFilter.type == 'range') {
            if ((productTypeFilter.selected_values['min_value'] != productTypeFilter.values['min_value']) ||
              (productTypeFilter.selected_values['max_value'] != productTypeFilter.values['max_value'])) {
              filters[index] = {
                'type': productTypeFilter.type,
                'name': productTypeFilter.name,
                'values': productTypeFilter.selected_values
              };
            }
            else {
              delete filters[index];
            }
          }
          else {
            filters[index] = {
              'type': productTypeFilter.type,
              'name': productTypeFilter.name,
              'values': productTypeFilter.selected_values
            };
          }
        }
      });
    }
    if (typeof this.props.productTypevariationFilters != 'undefined' && this.props.productTypevariationFilters.length > 0) {
      this.props.productTypevariationFilters.map((productTypeFilter, index) => {
        if (productTypeFilter.selected_values != "") {
          variationFilters[index] = {
            'type': productTypeFilter.type,
            'name': productTypeFilter.name,
            'values': productTypeFilter.selected_values
          };

        }
      });
    }
    // console.log(filters)
    // console.log(variationFilters)   
    this.setState({ filters: filters, variationFilters: variationFilters });
    if (typeof this.props.productTypeFilters != 'undefined')
      this.props.changeProductTypeFilters(this.props.productTypeFilters);
    if (typeof this.props.productTypeVariationFilters != 'undefined')
      this.props.changeProductTypeVariationFilters(this.props.productTypeVariationFilters);
    if (typeof this.props.changeVariationFilters != 'undefined')
      this.props.changeVariationFilters(variationFilters);
    if (typeof this.props.changeFilters != 'undefined')
      this.props.changeFilters(filters);
  }
}

export default CoreFilter;