import { getApiUrl, getImageUrl, getClientApiUrl } from './OperationsHelper.js';
import { axiosGet, axiosPost } from './RequestsHelper.js';
export const checkCredential = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/login_v1/store', formdata)
}

export const checkUserValid = async (authToken) => {
	return await axiosGet(await getApiUrl() + 'users/users/index?where[authToken]=' + authToken);
}

export const getUser = async (userId) => {
	return await axiosGet(await getApiUrl() + 'users/v1/customers_v1/index?where[users.id]=' + userId);
}

export const validateUser = (formdata) => {
	return axiosPost('http://digitalcatalog.in/saas/saas_verifications/store', formdata)
}

export const setUserToken = async (data) => {
	return await axiosPost(await getApiUrl() + 'user_device_token/store', data);
}

export const checkOtp = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/send_otp_v1/store', formdata)
}

export const registerUser = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/customers_v1/store', formdata);
}

export const registerDealer = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/dealers_v1/store', formdata);
}

export const forgotPassword = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/forgot_password_v1/store', formdata);
}

export const resetPassword = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/reset_password/store', formdata);
}

export const changePassword = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'users/v1/change_password_v1/store', formdata);
}

export const Logout = async () => {
	return await axiosGet(await getApiUrl() + 'users/logout/index');
}

export const verifyLinkPassword = async (params) => {
	return await axiosGet(await getApiUrl() + 'users/v1/reset_password_verifications_v1/create' + params);
}

export const getCategories = async () => {
	return await axiosGet(await await getApiUrl() + 'masters/v1/categories_v1');
}

export const getSubCategories = async (category) => {
	if (typeof category != 'undefined' && category != '')
		return await axiosGet(await await getApiUrl() + 'masters/v1/sub_categories_v1?where[fl_categories.slug]=' + category);
	else
		console.log('Category Name is required');
}

// api call for variations data
export const getProductDetail = async (productId, where = '') => {
	if ((typeof productId != 'undefined' && productId != '')) {
		var dealerId = '';
		return await axiosGet(await await getApiUrl() + 'products/v1/products_v1/view/' + productId + '?' + where);
	} else {
		console.log('Product Id is required');
	}
}

export const getCustomerHeaderFields = async () => {
	return await axiosGet(await await getApiUrl() + 'masters/v1/menu_categories_v1/index');
}

export const getDealerHeaderFields = async () => {
	return await axiosGet(await await getClientApiUrl() + 'masters/v1/seller_menus_v1/index');
}

export const getCustomerHomeBanner = async () => {
	return await axiosGet(await await getClientApiUrl() + 'masters/v1/home_banners_v1/index');
}

export const getProductListing = async (where = "") => {
	// const response = await axiosGet("http://localhost/dc.sports/" + 'products/v1/products_v1/index?' + where)
	const response = await axiosGet(await await getApiUrl() + 'products/v1/products_v1/index?' + where)
	// console.log(where)
	// console.log(response.data);
	return response
}

export const getCatalogListing = async (where) => {
	const response = await axiosGet(await await getApiUrl() + 'products/v1/products_v1/index?' + where)
	//  console.log(where)
	//  console.log(response.data);
	return response
	// return 

}

export const getCartListing = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/carts_v3');
}

export const updateCart = async (formdata, id) => {
	return await axiosPost(await getApiUrl() + 'transactions/v3/carts_v3/update/' + id, formdata);
}

export const updateWishList = async (formdata, id) => {
	return await axiosPost(await getApiUrl() + 'transactions/v3/wishlists_v3/update/' + id, formdata)
}

export const deleteCart = async (id) => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/carts_v3/delete/' + id)
}

export const deleteWishList = async (id) => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/wishlists_v3/delete/' + id)
}
export const addCompareProduct = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'products/v1/compares_v1/store', formdata);
}

export const getCompareProducts = async (formdata) => {
	return await axiosGet(await getApiUrl() + 'products/v1/compares_v1/index');
}

export const deleteCompareProduct = async (compare_id) => {
	return await axiosGet(await getApiUrl() + 'products/v1/compares_v1/delete/' + compare_id);
}

export const getImage = async (url) => {
	return await axiosGet(await getApiUrl() + url);
}

export const getGemetory = async (item_id = 1) => {
	return await axiosGet(await getApiUrl() + 'downloads/image_downloads/index?pdf=1&item_id=' + item_id);
}

export const storeCart = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'transactions/v3/carts_v3/store', formdata);
}

export const storeBulkCart = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'transactions/v1/bulk_carts_v1/store', formdata);
}

export const storeWishList = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'transactions/v3/wishlists_v3/store', formdata)
}

export const getCarts = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/carts_v3/index');
}

export const getWishList = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/wishlists_v3/index');
}

export const getCartItems = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/existing_cart_items_v3/index');
}

export const getWishListItems = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v3/existing_wishlist_items_v3/index');
}

export const getImageContent = async (moduleName, size, imageName, appendData) => {
	return await axiosGet(await getImageUrl() + 'images/image_downloads/index/' + moduleName + '/' + size + '/' + imageName + '/' + appendData);
}

export const placeOrder = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'transactions/v1/orders_v1/store', formdata);
}

export const getOrders = async (where) => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/orders_v1/index?' + where);
}

export const getQuotations = async (where) => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/orders_v1/index?' + where);
}

export const getOrderDetail = async (orderId) => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/order_details_v1/view/' + orderId);
}

export const getCountries = async () => {
	return await axiosGet(await getApiUrl() + 'locations/v1/countries_v1');
}

export const getStates = async (where = "") => {
	return await axiosGet(await getApiUrl() + 'locations/v1/states_v1/index/?show_all=true&&' + where);
}

export const getCities = async (where = "") => {
	return await axiosGet(await getApiUrl() + 'locations/v1/cities_v1/index/?show_all=true&&' + where);
}

export const getCatalogs = async () => {
	return await axiosGet(await await getApiUrl() + 'digital_catalogs/v1/catalogs_v1');
}

export const getCatalogData = async (id) => {
	return await axiosGet(await await getApiUrl() + 'digital_catalogs/v1/catalogs_v1/view/' + id);
}

export const getProductTypeFilteList = async (where) => {
	// made changes here ==>
	const response = await axiosGet(await await getApiUrl() + '/masters/v1/product_type_field_sections_v1?' + where);
	// console.log(response.data);
	let data = response.data;
    // If response data is a string, attempt to clean it and parse JSON
    if (typeof data === 'string') {
	const data = response.data;
    // Find the last occurrence of '</div>'
    const lastDivIndex = data.lastIndexOf('</div>');
    
    if (lastDivIndex !== -1) {
        // Keep everything after the first occurrence of '</div>' from the end of the string
        const cleanedData = data.substring(lastDivIndex + 6).trim(); // +6 to remove '</div>'
        //  console.log(cleanedData);
        response.data = JSON.parse(cleanedData);
    }
    }

	return response;
}

export const getProductTypevariationFilteList = async (where) => {
	return await axiosGet(await await getApiUrl() + 'masters/v1/product_type_variation_sections_v1?' + where);
}

export const getProductTypes = async () => {
	return await axiosGet(await await getApiUrl() + 'item_cores/item_product_type_fields');
}

export const saveCatalog = async (data, id = "") => {
	if (id == "")
		return await axiosPost(await getApiUrl() + 'digital_catalogs/v1/catalogs_v1/store', data);
	else
		return await axiosPost(await getApiUrl() + 'digital_catalogs/v1/catalogs_v1/update/' + id, data);
}

// On clicking add products -> button  present in catalog listing page
// on clicking +  icon on lsiting page
export const getCatalogProducts = async (catalogId) => {
	return await axiosGet(await getApiUrl() + 'digital_catalogs/v2/catalog_products_v2/view/' + catalogId);
}

export const getCartProducts = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/cart_products_v1/index');
}

export const getWishlistProducts = async () => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/wishlist_products_v1/index');
}

export const addRemoveCatalogProduct = async (data, id = "") => {
	if (id == "")
		return await axiosPost(await getApiUrl() + 'digital_catalogs/v1/catalog_products_v1/store', data);
	else
		return await axiosPost(await getApiUrl() + 'digital_catalogs/v1/catalog_products_v1/delete/' + id);
}

export const getDealer = async (where = '') => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/dealer_v1/index' + where);
}

export const getDealerLeader = async (where = '') => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/seller_due_v1/index');
}

export const getSellerClassifications = async (where = '') => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/seller_classifications_v1/index');
}

export const subscribeNewsletter = async (formdata) => {
	return await axiosPost(await getClientApiUrl() + 'news_letters/v1/news_letter_subscribers_v1/store', formdata);
}

export const getDealerProfileView = async (dealerId) => {
	return await axiosGet(await getApiUrl() + 'users/v1/dealers_v1/view/' + dealerId);
}

export const checkDealerStatus = async (dealerId) => {
	return await axiosGet(await getApiUrl() + 'users/v1/dealers_v1/view/' + dealerId)
}

export const getTallyVoucher = async (id, formdata) => {
	return await axiosPost(await getClientApiUrl() + 'tally_vouchers/tally_vouchers/view/' + id, formdata);
}

export const getSalesVoucher = async (voucherId) => {
	return await axiosGet(await getClientApiUrl() + 'tally_vouchers/v1/tally_vouchers_v1/view/' + voucherId);
}

export const checkHash = async () => {
	return await axiosGet(await getApiUrl() + "digital_catalogs/v1/check_hash_exists_v1/index");
}

export const storeAddresses = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'checkouts/v1/addresses_v1/store', formdata);
}

export const updateAddresses = async (formdata, id) => {
	return await axiosPost(await getApiUrl() + 'checkouts/v1/addresses_v1/update/' + id, formdata);
}

export const getAddresses = async (where) => {
	return await axiosGet(await getApiUrl() + 'checkouts/v1/addresses_v1/index?' + where);
}

export const validateSellerSelection = async (userId) => {
	return await axiosGet(await getApiUrl() + 'transactions/v4/seller_selections_v4');
}

export const updateSellerId = async (formdata, id) => {
	return await axiosPost(await getApiUrl() + 'checkouts/v1/seller_transactions_v1/update/' + id, formdata);
}


export const getOrderSummery = async (where) => {
	return await axiosGet(await getApiUrl() + 'transactions/v4/order_summeries_v4' + where);
}

export const getSellers = async (stateName = "") => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/sellers_v1');
}

export const getDealerCatalogs = async () => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/dealer_catalogs_v1');
}

export const getDealerCategories = async (where) => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/seller_categories_v1?' + where);
}

export const getDealerBrands = async (where) => {
	return await axiosGet(await getClientApiUrl() + 'masters/v1/seller_brands_v1?' + where);
}

export const getBrands = async () => {
	//made changes here
	return await axiosGet(await getClientApiUrl() + 'masters/v1/brands_v1?show_all=true');
}

export const checkInventory = async () => {
	return await axiosGet(await getApiUrl() + 'inventories/v1/check_inventories_v1');
}

export const getSettings = async (where = '') => {
	return await axiosGet(await getApiUrl() + 'settings/v1/mobile_settings_v1?' + where);
}

export const getItemVariations = async (productId) => {
	return await axiosGet(await getApiUrl() + 'transactions/v1/item_variations_v1/view/' + productId);
}

export const applyCouponCode = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'discounts/v1/discounts_v1/store', formdata);
}

export const removeCouponCode = async () => {
	return await axiosPost(await getApiUrl() + 'discounts/v1/remove_coupon_codes_v1/store');
}

export const getStoreCreditBalance = async (userId) => {
	return await axiosGet(await getClientApiUrl() + 'transactions/v1/store_credits_v1?where[store_credits.user_id]=' + userId);
}

export const applyStoreCredit = async (formdata) => {
	return await axiosPost(await getClientApiUrl() + 'transactions/v1/store_credits_v1/store', formdata);
}

export const getRedirectUrl = async (url) => {
	return await axiosGet(await getApiUrl() + 'redirects/v1/redirect_urls_v1?where[from]=' + url);
}

export const getBreadcrumbsabc = async (where) => {
	// console.log(where);
	return await axiosGet(await getApiUrl() + 'breadcrumbs/v1/breadcrumbs_v1?' + where);
}

export const getOrderStates = async () => {
	return await axiosGet(await getApiUrl() + 'sys/v1/state_machines_v1');
}

export const getQuotationStates = async () => {
	return await axiosGet(await getApiUrl() + 'sys/v1/state_machines_v1');
}

export const getMetaTags = async (where) => {
	// made changes here ==>
	const response = await axiosGet(await getApiUrl() + 'seo/v1/meta_tags_v1?' + where);
	// console.log(response)
	return response;
}

export const checkPinCode = async (pincode = '') => {
	return await axiosGet(await getApiUrl() + 'delhivery/delhiveries/index?pincode=' + pincode);
}

export const getItemInventory = async (id) => {
	return await axiosGet(await getApiUrl() + 'inventories/v1/item_inventories_v1/view/' + id);
}

export const storeContactus = async (formdata) => {
	return await axiosPost(await getClientApiUrl() + 'contact_us/v1/contact_us_v1/store', formdata, false);
}

export const updateSuccessPayment = async (id, formdata) => {
	return await axiosPost(await getApiUrl() + 'payments/v1/success_v1/update/' + id, formdata);
}

export const updateFailedPayment = async (id, formdata) => {
	return await axiosPost(await getApiUrl() + 'payments/v1/failed_v1/update/' + id, formdata);
}

export const createOrder = async (formdata) => {
	return await axiosPost(await getApiUrl() + 'payments/v1/generate_orders_v1/create', formdata);
}

export const cancelOrder = async (formdata) => {
	return await axiosPost(await getClientApiUrl() + 'payments/v1/refund_requests_v1/store', formdata, false);
}

export const cancelOrderItem = async (id, formdata) => {
	return await axiosPost(await getApiUrl() + 'orders/v1/order_statuses_v1/update/' + id, formdata);
}

export const selectTags = async () => {
	const response = await axiosPost(await getClientApiUrl() + 'products/v1/product_tags_v1/index?show_all=true');
	// console.log(response);
	return response
}

