import { getBreadcrumbsabc } from './ServicesHelper';

export const getBreadcrumbsArray = async (path) => {
  var breadcrumbs = [];
  var where = '';
  var route_4;

  var route = path.url.split('/');
  route = route[1];
  route_4 = route[4];

  const paramOne = path.params.paramOne;
  const paramTwo = path.params.paramTwo;
  const paramThree = path.params.paramThree;
  const paramFour = path.params.paramFour;

  if (route == 'bpl' || route == 'cpl') {
    where = 'page=' + route + '&slug=' + paramOne;
  }

  if (route == 'pl') {
    where = 'page=' + route;
    if (typeof paramOne != 'undefined')
      where += '&category_slug=' + paramOne;
    if (typeof paramTwo != 'undefined')
      where += '&sub_category_slug=' + paramTwo;
    if (typeof paramThree != 'undefined')
      where += '&sub_sub_category_slug=' + paramThree;
  }

  if (route == 'pre-booking') {

  }
  if (route == 'bpp') {
    where = 'page=' + route + '&brand_slug=' + paramOne + '&product_slug=' + paramTwo;
  }

  if (route == 'cpp') {
    where = 'page=' + route + '&catalog_slug=' + paramOne + '&product_slug=' + paramTwo;
  }

  // if (route == 'pp') {
  //   var slugs = path.url.split('/');
  //   console.log(slugs);
  //   var product_slug = slugs[slugs.length - 1];
  //   where = 'page=' + route + '&product_slug=' + product_slug;
  //   console.log(where);
  // }
  if (route == 'pp') {
    var slugs = path.url.split('/');
    //  console.log(slugs);
    var product_slug = slugs[slugs.length - 1];

    switch(slugs.length) {
        case 3:
            where = 'page=' + route + '&product_slug=' + product_slug;
            break;
        case 4:
            where = 'page=' + route + '&category_slug=' + slugs[2] + '&product_slug=' + product_slug;
            break;
        case 5:
            where = 'page=' + route + '&category_slug=' + slugs[2] + '&sub_category_slug=' + slugs[3] + '&product_slug=' + product_slug;
            break;
        case 6:
            where = 'page=' + route + '&category_slug=' + slugs[2] + '&sub_category_slug=' + slugs[3] + '&sub_sub_category_slug=' + slugs[4] + '&product_slug=' + product_slug;
            break;
        default:
            where = 'page=' + route + '&product_slug=' + product_slug;
    }

    //  console.log(where);
}
  // console.log(where);
  var response = await getBreadcrumbsabc(where);
  if (response.data.status == 'success') {
    breadcrumbs = response.data.data;
    // console.log(breadcrumbs);
  }

  return breadcrumbs;
}
