import React, { Component } from 'react'
import { toast } from 'react-toastify';

import MasterProduct from './MasterProduct';
import { getImageUrl, getImagePath, mobile } from '../../helpers/OperationsHelper';
import {
  getProductDetail, storeCart, updateCart, getCartItems, addCompareProduct,
  getCompareProducts, deleteCompareProduct, getImageContent, checkPinCode
} from '../../helpers/ServicesHelper';
import { getBreadcrumbsArray } from '../../helpers/BreadcrumbsHelper';

import { connect } from 'react-redux'
import cookie from 'react-cookies';

import videoImg0 from '../../../web/assets/images/avanti/scott6.jpg';
import videoImg1 from '../../../web/assets/images/avanti/scott2.jpg';
import videoImg2 from '../../../web/assets/images/avanti/scott5.jpg';
import videoImg3 from '../../../web/assets/images/avanti/scott8.png';


class CoreProductDetail extends MasterProduct {
  constructor(props) {
    super(props);
    this.state = {
      productDetail: {},
      quantity: 1,
      selectedVariations: {},
      selectedVariationsNames: {},
      deliveryPrefrences: [],
      productTypeFields: [],
      variations: [],
      fl_item_recommendations: [],
      images: [],
      allImages: [],
      colorImages: [],
      keyFeatures: [],
      specifications: [],
      technologies: [],
      errors: [],
      geometry_attachment: "",
      product_type_fields: '',
      recommendation: [],
      productName: '',
      compareProducts: [],
      addToCart: '',
      showVariationModal: false,
      errors: [],
      loader: true,
      cartButtonLoader: false,
      wishlistButtonLoader: false,
      breadcrumbs: [],
      item_metal: [],
      item_gemstones: [],
      item_diamonds: [],
      modelNo: '',
      viewMessage: '',
      concatValues: '',
      quantities: {},
      inventories: [],
      zoomVisible: false,
      currentZoomIndex: 0,
      setIsSearchVisible: false,
      paginationLoader: false,
      pinValidate: true,
      message: '',
      variationSkuCombinations: [],
      selectedVariationKey: '',
      selectVariationSkuCode: '',
      sales_login: 0,
      Im: [videoImg0, videoImg1, videoImg2, videoImg3]
    }
  }

  // when component is rendered
  async componentDidMount() {
    window.history.pushState(null, null, window.location.pathname);
    //window.addEventListener('popstate', this.onBackButtonEvent());
    this.getProductData();
    if (cookie.load('sales_login')) {
      this.setState({ sales_login: cookie.load('sales_login') })
    }
    window.scrollTo(0, 0);
  }

  async getProductData() {
    await this.getProductDetails(); //api on page load
    await this.getBreadcrumbs(); //api on page load
    await this.getCompareProducts();  //api on page load
  }

  //when route of the page is updated
  componentDidUpdate(nextProps, nextState) {
    if (nextProps.match.url != this.props.match.url) {
      window.history.pushState(null, null, window.location.pathname);
      this.setState({ productDetail: [], loader: true })
      window.scrollTo(0, 0);
      this.getProductData();
    }
  }

  //api on page load
  async getBreadcrumbs() {
    if (mobile == false) {
      var breadcrumbs = await getBreadcrumbsArray(this.props.match);
      this.setState({ breadcrumbs: breadcrumbs });
    }
  }

  //api call on change
  async checkPinCode(pincode) {
    if (pincode.length > 5 && isNaN(pincode) == false) {
      let pincodeValidate = await checkPinCode(pincode);
      if (pincodeValidate.data.delivery_codes.length > 0) {
        this.setState({ pinValidate: true, 'message': 'Pincode ' + pincode + ' is in service to deliver product at your doorstep' })
      } else {
        this.setState({ pinValidate: false, 'message': 'Currenlty we are not delivering product on ' + pincode + ', But you can pick order from your nearest seller.' })
      }
    }
  }

  //api on page load
  async getProductDetails(where = '') {
    var path = this.props.match.url.split('/');
    var slug = path[path.length - 1];

    var productDetails = await getProductDetail(slug, where);
    if (typeof productDetails.data.data !== 'undefined' && productDetails.data.status === 'success') {
      await this.setState({
        'productDetail': productDetails.data.data,
        'variations': productDetails.data.data.product_type_variations,
        'productTypeFields': productDetails.data.data.product_type_fields,
        'keyFeatures': productDetails.data.data.key_features,
        'specifications': productDetails.data.data.specifications,
        'technologies': productDetails.data.data.technologies,
        'item_id': productDetails.data.data.id,
        'whyToBuy': productDetails.data.data.why_to_buy,
        'recommendation': productDetails.data.data.recommendation,
        'geometry_attachment': productDetails.data.data.geometry_attachment,
        'inventories': productDetails.data.data.inventories,
        'item_metals': productDetails.data.data.item_metals,
        'item_diamonds': productDetails.data.data.item_diamonds,
        'item_gemstones': productDetails.data.data.item_gemstones,
        'deliveryPrefrences': productDetails.data.data.delivery_prefrences,
        'loader': false,
        'variationSkuCombinations': productDetails.data.data.variation_sku_combinations
      });
      // console.log("variations obj: ", productDetails.data.data.product_type_variations)
      this.setSelectedVariations();
      this.getItemInventory(productDetails.data.data.id, 0, 0, 0, 0);
      this.setState({ loader: false })

      //if (productDetails.data.data.images.length > 0)
      await this.getImages(productDetails.data.data.images);
      // await this.getColorImages(productDetails.data.data.images);
    }
  }

  //no api call
  async getImages(images) {
    // console.log(images);
    var imageArray = this.state.images;
    var length = images.length;
    if (length > 0) {
      for (var i = 0; i < length; i++) {
        var thumb_image = this.props.settings.image_url + 'products/images/thumb/' + images[i].name;
        var original_image = this.props.settings.image_url + 'products/images/medium/' + images[i].name;
        imageArray[i] = { original: original_image, thumbnail: thumb_image, 'name': images[i].name, color: images[i].sku_no, 'model_no': images[i].model_no, 'sequence': images[i].sequence };
      }
    } else {
      var original_image = this.props.settings.image_url + '/application_images/original/' + this.props.settings.error_image;
      imageArray[0] = { original: original_image };
    }
    // console.log(imageArray);

    await this.setState({ 'images': imageArray, allImages: imageArray });
    this.setSelectedVariations();
  }

  //no api call
  async setSelectedVariations() {
    var selectedVariations = this.state.selectedVariations;
    // console.log(selectedVariations);
    // console.log(this.state.variations);
    // console.log(this.state.variations.length)
    for (var i = 0; i < this.state.variations.length; i++) {
      this.state.variations[i].options.map((value, index) => {
        if (index === 0) {
          selectedVariations[this.state.variations[i].name] = value.sku_no
          this.selectvariation(this.state.variations[i].name, value.sku_no, 1, value.value);
          //this.selectvariationname(this.state.variations[i].name, value.value, 1);
        }
      })
    }
    // console.log(selectedVariations)
    this.setState({ selectedVariations: selectedVariations });
    this.checkVariationModel(); 
  }
}

export default CoreProductDetail; 