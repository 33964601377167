import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
// import {useNavigate} from 'react-router-dom';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Collapse, Form, Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import { connect } from 'react-redux';

import CoreFilter from '../../../../core/components/products/CoreFilter';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

class Filter extends CoreFilter {



  render() {
    // console.log(window.location.href);
    const { t } = this.props;
    const x = 2;
    return (
      <div>
        {
          (typeof this.props.productTypeFilters != "undefined") && this.props.productTypeFilters.map((filterVal, index) => (
            (filterVal.type === 'range') ? (
              <div key={'div' + index} className="mt-3 mb-3 custom_range_slider">
                <h6 className="black bold text-uppercase">{filterVal.name}</h6>
                <Range min={filterVal.values.min_value}
                  max={filterVal.values.max_value}
                  defaultValue={[filterVal.selected_values.min_value, filterVal.selected_values.max_value]}
                  step={1}
                  onAfterChange={(sliderValues) => this.changeFilterState(sliderValues, filterVal.name, filterVal.type)} />
                <div className="d-inline-block w-100">
                  <div className="slider_label float-left">
                    <span>{filterVal.selected_values.min_value}</span>
                  </div>
                  <div className="slider_label float-right">
                    <span>{filterVal.selected_values.max_value}</span>
                  </div>
                </div>
              </div>) :
              (filterVal.type === 'multiselect') && (
                <div key={'div' + filterVal.name.replace(/\s/g, '') + "_" + index} id={"filter_" + filterVal.name.replace(/\s/g, '') + "_" + index} className="custom_collapse">
                  <div className="custom_toggle">
                    <a data-toggle="collapse" href={"#collapse_" + filterVal.name.replace(/\s/g, '') + "_" + index} className="gray">
                      <span className="text-uppercase bold flex-fill mb-1">{filterVal.name}</span>
                    </a>
                  </div>
                  <div id={"collapse_" + filterVal.name.replace(/\s/g, '') + "_" + index} className="custom_toggle_data collapse show" data-parent={"#filter_" + filterVal.name.replace(/\s/g, '') + "_" + index}>
                    <Form>
                      {
                        filterVal.values.map((value, key) => (
                          <Form.Check key={'input' + key} type="checkbox" id={"model_" + index + "_" + value.replace(/\s/g, '')}>
                            {window.location.href === "http://localhost:3000/pre-booking" && value === "Prebooking" ?
                              <>
                                <Form.Check.Input className="customCheck" checked={true} onChange={(e, name, type) => this.changeFilterState(value, filterVal.name, filterVal.type)} type="checkbox" isValid />
                                <Form.Check.Label>{value}</Form.Check.Label>
                                {/* <h6>{value}</h6> */}
                              </> : <>
                                <Form.Check.Input className="customCheck" onChange={(e, name, type) => this.changeFilterState(value, filterVal.name, filterVal.type)} type="checkbox" isValid />
                                <Form.Check.Label>{value}</Form.Check.Label>
                              </>
                            }


                          </Form.Check>
                        ))
                      }
                    </Form>

                    {/* {window.location.href == "http://localhost:3000/pre-booking" && {value} === "Prebooking" ?  : } */}
                  </div>
                </div>
              )
          )
          )
        }
      </div>
    );
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (data) => { dispatch({ type: 'SET_FILTERS', payload: data }) },
    setPage: (page) => { dispatch({ type: 'SET_FILTERPAGE', payload: page }) },
  }
}

export default withTranslation()(connect(null, mapDispatchToProps)(Filter));
