import React from 'react';
import {
  Container,
  Row,
  Col,
  Carousel,
  Table,
  Tabs,
  Tab,
  Modal,
  Form,
} from 'react-bootstrap';

import { RWebShare } from 'react-web-share';
import Button from 'react-bootstrap-button-loader';
import { Route, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CoreProductDetail from '../../../../core/components/products/CoreProductDetail';
import {
  numberWithFloat,
  dealersPortal,
} from '../../../../core/helpers/OperationsHelper';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Tostify from '../../../components/Toaster';

import Slider from '../components/Slider.js';
import SimilarProduct from '../components/SimilarProduct';
import ProductDetailsTabs from '../components/ProductDetailsTabs';
import ProductInventory from './ProductInventory';
import SizeChartModal from '../components/SizeChartModal';
import FindSizeModal from '../components/FindSizeModal';
import CompareModal from '../components/CompareModal';
import SingleVariationSelection from '../components/SingleVariationSelection';
import MultipleVariationSelection from '../components/MultipleVariationSelection';
import TransactionButton from 'react-bootstrap-button-loader';
import ProductVariations from '../components/ProductVariations';
import Loader from '../../../components/Loader';
import MetaTags from '../../../components/MetaTags';
import videoImg from '../../../assets/images/products/apparel.jpg';
import { ResourceStore } from 'i18next';
const key = 'rzp_live_UkzZnx3cZ9MnPa';

class ProductDetails extends CoreProductDetail {
  renderWidget = () => {
    const widgetConfig = {
      key: key,
      amount: this.state.productDetail.product_type_field_value * 100,
    };
    const rzpAffordabilitySuite = new window.RazorpayAffordabilitySuite(
      widgetConfig,
    );
    rzpAffordabilitySuite.render();
  };

  // componentDidMount() {
  // this.renderWidget();
  // }
  // async componentDidMount()
  // {
  //   // console.log("mounting");
  //   const res = await fetch("")
  // }
  async componentDidUpdate() {
    // if(this.state.productDetail) 
      // document.title = this.state.productDetail.name;
    if (this.state.loader == false) await this.renderWidget();
  }
  
  render() {
    var getURL = window.location.pathname;
    // console.log("detail URL", getURL);
    var isPrebooking;
    const {
      quantity,
      compareProducts,
      images,
      item_gemstones,
      item_metals,
      item_diamonds,
      Im,
    } = this.state;
    const { t } = this.props;
    const test = numberWithFloat(
      this.state.productDetail.product_type_field_value - this.state.productDetail.product_type_field_value 
      * (this.state.productDetail.dealer_discount /100),
    )
    // console.log("test ==> ", test)
    // console.log(this.state.productDetail.videos);
    // console.log("event split url", window.location.pathname.split('/'));
    isPrebooking = window.location.pathname.split('/');
    // console.log("events ", isPrebooking[1]);
    // console.log(this.state.productDetail);
    // console.log(this.state.productDetail.images);
    // console.log(getURL[1]);
    return (
      <React.Fragment>
        <MetaTags {...this.props} />
        <div className="main scott_product mt-4">
          {this.state.loader === false && (
            <React.Fragment>
              <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              <Tostify />
              {Object.keys(this.state.productDetail).length > 0 && (
                <div className="main_section mb-5">
                  <Container className="product_detail_container">
                    {this.props.catalogId != '' && (
                      <Row className="mb-3">
                        <Col xs={12} className="text-left">
                          <div
                            onClick={() =>
                              window.location.assign(
                                '/cpl/' + this.props.catalogSymbol,
                              )
                            }>
                            <span className=" bold text-left">
                              {t('yourAddingProduct')}{' '}
                              <Link> {this.props.catalogName} </Link>{' '}
                              {t('catalog')}.
                            </span>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col lg={6}>
                        {this.state.productDetail.brand_image != '' && (
                          <img
                            src={
                              this.props.settings.image_url +
                              '/brands/original/' +
                              this.state.productDetail.brand_image
                            }
                            width="100px"
                          />
                        )}
                        <Slider images={images} />
                      </Col>
                      {isPrebooking[3] === 'bergamont' ? (
                        <Col lg={6}>
                          <div>

                            <h3 className='text-dark' style={{ color: 'black', fontWeight: '800' }}>
                              BERGAMONT I RIDE CHALLENGE 3.0
                            </h3>
                            <div>
                              {/* <h5>LOCATION:</h5> */}
                              <h4 className='text-dark mt-3' style={{ color: 'black' }}>LOCATION: Anand / Hisar / Kanyakumari / Nashik / Rajkot / Sonipat / Shillong / Kanpur</h4>
                            </div>
                            <h4 className='text-dark mt-3 ' style={{ color: 'black' }}> DATES : 7th MAY – 4th JUNE</h4>
                            <h4 style={{ color: 'black', fontWeight: '500', marginTop: "25px" }}>
                              Event Details :
                            </h4>
                            <div className="text-dark mt-4">
                              {/* <h5 style={{color: 'black', fontWeight: '500'}}>
                                BODY SCAN
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Are you ready to take on the ultimate cycling challenge with your city?
                                Bergamont I Ride Challenge 3.0 is here and it&#39;s time to gear up for the ride.
                              </p>
                            </div>

                            <div className=" mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                BIKE SEARCH
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Bergamont I Ride Challenge 3.0 is a fun tournament style cycling challenge with three exhilarating
                                ride distances of 50, 75, and 100 km, there's something for every level of cyclist to conquer.
                              </p>
                            </div>

                            <div className="text-dark mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                INSTANT BODY SCAN AND BIKEFIT REPORT
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Eight cities compete in a knock-out style challenge, and the qualifier moves to the next challenge.
                                The qualifying criteria is the cities with the highest number of participants in each ride. Qualifiers at
                                every stage will receive exciting vouchers and Winning city of the challenge will receive exciting
                                goodie. Everyone who completes all the three challenges will receive the coveted BERGAMONT I
                                Ride challenge medal. So what are you waiting for, gather your cycling buddies and ride for your city.
                              </p>
                            </div>
                            <div className="text-dark mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                INSTANT BODY SCAN AND BIKEFIT REPORT
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                The eliminated cities will continue riding for the rest of the challenge in the non-competitive
                                category. Non-Competitive riders can also win some awesome goodies, and a morning full of fun
                                cycling with your mates, endless selfies, delicious local breakfast.
                              </p>
                            </div>
                            <div className="text-dark mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                INSTANT BODY SCAN AND BIKEFIT REPORT
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Achieve self-goals together with your mates and help your city win. Win vouchers worth Rs
                                8,00,000/-<sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                              </p>
                            </div>
                            <div className="text-dark mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                INSTANT BODY SCAN AND BIKEFIT REPORT
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Register for all three rides and receive a special i-RIDE Fan Jersey!
                              </p>
                            </div>
                            <div className="text-dark mt-2">
                              {/* <h5 style={{ color: 'black', fontWeight: '500' }}>
                                INSTANT BODY SCAN AND BIKEFIT REPORT
                              </h5> */}
                              <p style={{ color: 'black' }}>
                                Save your dates and Register soon
                              </p>
                            </div>

                            {/* <a href="https://www.help.smartfit.bike/article/334-0" target="_blank">
                              Smart Fit
                            </a> */}
                            <div className="mt-5">
                              <Button
                                loading={this.state.cartButtonLoader}
                                className="btn-lg mr-2"
                                style={this.props.styles.primary_btn}
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = `https://customerconnect.sportnetwork.in/events/bergmond_i_rides`
                                  // window.location.href = `https://staging-scott-sports.ascratech.com/events/bike_fit_services`;
                                  // window.location.href = `https://customerconnect.sportnetwork.in/prebooking/prebookings?slug=${this.state.productDetail.slug}`;

                                  // console.log(`https://customerconnect.sportnetwork.in/prebooking/prebookings?slug=${this.state.productDetail.slug}`);
                                }}>
                                REGISTER YOUR RIDE
                              </Button>
                            </div>

                            {this.state.productDetail.videos.length > 0 ? (
                              <>
                                <h6 className="mt-5 mb-0 pr-3 bold text-nowrap">
                                  Point Of View:{' '}
                                </h6>
                                <div>
                                  {this.state.productDetail.videos.map(
                                    (video, index) => (
                                      <Row className="pd7">
                                        <Col xs={3}>
                                          <div className="video_sec" key={index}>
                                            <a
                                              data-toggle="modal"
                                              data-target={'#videoModal' + index}>
                                              <img
                                                src={Im[index]}
                                                className="img-fluid"
                                              />
                                              <div className="video_action">
                                                <i className="far fa-play-circle"></i>
                                                <p className="mb-0">Watch here</p>
                                              </div>
                                            </a>
                                          </div>

                                          <div
                                            className="modal fade"
                                            id={'videoModal' + index}
                                            tabindex="-1"
                                            role="dialog"
                                            aria-hidden="true">
                                            <div
                                              className="modal-dialog modal-dialog-centered modal-lg"
                                              role="document">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">
                                                      &times;
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <iframe
                                                    width="100%"
                                                    height="400"
                                                    src={
                                                      video.video_url_1 +
                                                      '?autoplay=1'
                                                    }
                                                    frameborder="0"
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    ),
                                  )}
                                </div>
                              </>
                            ) : (
                              <h6></h6>
                            )}
                          </div>
                        </Col>
                      ) : (
                        <Col lg={6}>
                          <div className="product_detail mt-3 mt-lg-0">
                            <div className="">
                              <div className="ref">
                                <Row>
                                  <Col xs={12}>
                                    <Row>
                                      <Col sm={12}>
                                        <div>
                                          <h1 className="page_heading bold text-uppercase black mb-0">
                                            {this.state.productDetail.name}
                                          </h1>
                                          {this.state.modelNo != '' && (
                                            <p className="bold mb-0">
                                              {t('model')} :{' '}
                                              {this.state.modelNo}
                                            </p>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                    {this.state.productDetail
                                      .product_type_field_currency == 1 && (
                                        <Row className="mt-3">
                                          <Col sm={12}>
                                            <h3>
                                              <i className="fal fa-rupee-sign"></i>
                                              <span>
                                                {
                                                  numberWithFloat(
                                                    this.state.productDetail.product_type_field_value - this.state.productDetail.product_type_field_value 
                                                    * (this.state.productDetail.dealer_discount /100),
                                                  )
                                                }
                                              </span>
                                              {
                                                
                                              }
                                              <React.Fragment>
                                                <strike className="pl-2">
                                                  {this.state.productDetail
                                                    .secondary_product_type_field_value !=
                                                    null &&
                                                    parseInt(
                                                      this.state.productDetail
                                                        .secondary_product_type_field_value,
                                                    ) >
                                                    parseInt(
                                                      this.state.productDetail
                                                        .product_type_field_value,
                                                    ) ? (
                                                    <span
                                                      style={{ color: '#565959' }}>
                                                      {' ' +
                                                        numberWithFloat(
                                                          this.state.productDetail
                                                            .secondary_product_type_field_value,
                                                        )}
                                                    </span>
                                                  ) : (
                                                    <span></span>
                                                  )}
                                                </strike>
                                              </React.Fragment>
                                            </h3>
                                          </Col>
                                        </Row>
                                      )}

                                    {this.state.productDetail
                                      .product_type_field_currency == 0 && (
                                        <Row className="mt-3">
                                          <Col sm={12}>
                                            <h3>
                                              <span>
                                                {numberWithFloat(
                                                  this.state.productDetail
                                                    .product_type_field_value,
                                                )}{' '}
                                                {
                                                  this.state.productDetail
                                                    .product_type_field_unit
                                                }
                                              </span>
                                            </h3>
                                          </Col>
                                        </Row>
                                      )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    {this.state.productTypeFields.length > 0 &&
                                      this.state.productTypeFields.map(
                                        (productTypeField, index) => (
                                          <Row key={index} className="mt-3">
                                            <Col xs={4}>
                                              <h6 className="text bold">
                                                {productTypeField.field_name} :
                                              </h6>
                                            </Col>
                                            <Col xs={8}>
                                              <span>
                                                {numberWithFloat(
                                                  productTypeField.field_value,
                                                )}{' '}
                                                {productTypeField.unit}
                                              </span>
                                            </Col>
                                          </Row>
                                        ),
                                      )}
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            {this.state.keyFeatures != '' && (
                              <div>
                                <h6 className="mt-2 mb-2 text bold text-left p-0">
                                  {t('keyFeatures')}
                                </h6>
                                <ul className="key-features-list list-unstyled pl-0 black">
                                  {this.state.keyFeatures.map(
                                    (fetaure, feture_key) => (
                                      <li key={feture_key}>{fetaure}</li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            )}

                            {this.state.sales_login == 1 && (
                              <ProductInventory
                                id={this.state.productDetail.id}
                                selectvariation={(name, value, changeImage) =>
                                  this.selectvariation(name, value, changeImage)
                                }
                                setQuantities={(value, id) =>
                                  this.setQuantities(value, id)
                                }
                                quantities={this.state.quantities}
                                errors={this.state.errors}
                                inventories={this.state.inventories}
                                checkColorOption={true}
                              />
                            )}

                            {!dealersPortal &&
                              this.state.productDetail.size_chart != '' &&
                              this.state.productDetail.size_chart != null && (
                                <div className="product_sizes mt-2 mb-2">
                                  <div className="d-flex mb-2">
                                    <a
                                      href=""
                                      data-toggle="modal"
                                      data-target="#size-modal">
                                      {t('sizeChart')}
                                    </a>
                                  </div>
                                </div>
                              )}

                            {!dealersPortal &&
                              this.state.variations != '' &&
                              this.state.variations.map(
                                (variation, variation_key) => (
                                  <div
                                    key={variation_key}
                                    className="product_sizes mt-3">
                                    <div className="mb-2 align-items-center">
                                      <h6 className="mb-0 pr-3 text-nowrap">
                                        <span className="bold">
                                          {t('select')} {variation.name}{' '}
                                        </span>{' '}
                                        :{' '}
                                        <span>
                                          {
                                            this.state.selectedVariationsNames[
                                            variation.name
                                            ]
                                          }
                                        </span>
                                      </h6>
                                      <div className="align-items-center">
                                        {this.state.variations.length > 1 ? (
                                          <MultipleVariationSelection
                                            variation={variation}
                                            selectedVariationKey={
                                              this.state.selectedVariationKey
                                            }
                                            variationSkuCombinations={
                                              this.state
                                                .variationSkuCombinations
                                            }
                                            selectVariationSkuCode={
                                              this.state.selectVariationSkuCode
                                            }
                                            selectvariation={(
                                              name,
                                              sku_no,
                                              popup,
                                              value,
                                            ) =>
                                              this.selectvariation(
                                                name,
                                                sku_no,
                                                popup,
                                                value,
                                              )
                                            }
                                            selectedVariations={
                                              this.state.selectedVariations
                                            }
                                            settings={this.props.settings}
                                            changeImage={1}
                                          />
                                        ) : (
                                          <SingleVariationSelection
                                            variation={variation}
                                            selectedVariationKey={
                                              this.state.selectedVariationKey
                                            }
                                            variationSkuCombinations={
                                              this.state
                                                .variationSkuCombinations
                                            }
                                            selectVariationSkuCode={
                                              this.state.selectVariationSkuCode
                                            }
                                            selectvariation={(
                                              name,
                                              sku_no,
                                              popup,
                                              value,
                                            ) =>
                                              this.selectvariation(
                                                name,
                                                sku_no,
                                                popup,
                                                value,
                                              )
                                            }
                                            selectedVariations={
                                              this.state.selectedVariations
                                            }
                                            settings={this.props.settings}
                                            changeImage={1}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}

                            {this.props.settings.share_product == 'Yes' && (
                              <RWebShare
                                data={{
                                  url: 'http://dealers-ssi.ascratech.com/pp/mountain/scoot-spark-rc-900-sl-axs-',
                                  text: this.state.viewMessage + '\n',
                                  title: 'Share Via',
                                }}
                                onClick={() => this.shareMessage()}>
                                <button className="float_icon" variant="dark">
                                  <i className="fas fa-share-alt pr-2 "></i>
                                </button>
                              </RWebShare>
                            )}

                            {!dealersPortal &&
                              typeof this.props.settings.quantity_selector !=
                              'undefined' &&
                              this.props.settings.quantity_selector ===
                              'Yes' && (
                                <div className="d-flex align-items-center">
                                  <h6 className="mb-0 pr-3 bold text-nowrap">
                                    {t('select')} {t('quantity')} :
                                  </h6>
                                  <div>
                                    <div className="quantity_input_div">
                                      <button
                                        className="quantity_btn"
                                        onClick={() =>
                                          this.changeQuantity(
                                            parseInt(quantity) - 1,
                                          )
                                        }>
                                        <i className="fal fa-minus"></i>
                                      </button>
                                      <input
                                        type="text"
                                        className="quantity_input"
                                        value={quantity}
                                      />
                                      <button
                                        className="quantity_btn"
                                        onClick={() =>
                                          this.changeQuantity(
                                            parseInt(quantity) + 1,
                                          )
                                        }>
                                        <i className="fal fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {typeof this.props.settings.table_field !=
                              'undefined' &&
                              this.props.settings.table_field === 'YES' && (
                                <div className="mt-3 mb-3">
                                  <Tabs
                                    defaultActiveKey="metal"
                                    className="table_tabs">
                                    <Tab eventKey="metal" title="metal">
                                      <Table className="tabs_table mb-0">
                                        {item_metals.length > 0 ? (
                                          <thead>
                                            <tr>
                                              <th>{t('tone')}</th>
                                              <th>{t('purity')}</th>
                                              <th className="text-right">
                                                {t('weightGms')}{' '}
                                              </th>
                                            </tr>
                                          </thead>
                                        ) : (
                                          <thead>
                                            <center>{t('noRecord')}</center>
                                          </thead>
                                        )}
                                        <tbody>
                                          {item_metals.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item.name}</td>
                                              <td>{item.purity}</td>
                                              <td className="text-right">
                                                {numberWithFloat(item.weight)}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Tab>
                                    <Tab eventKey="diamonds" title="diamonds">
                                      <Table className="tabs_table mb-0">
                                        {item_diamonds.length > 0 ? (
                                          <thead>
                                            <tr>
                                              <th>{t('shape')}</th>
                                              <th>{t('pieces')}</th>
                                              <th className="text-right">
                                                {t('weightCts')}
                                              </th>
                                            </tr>
                                          </thead>
                                        ) : (
                                          <thead>
                                            <center>{t('noRecord')}</center>
                                          </thead>
                                        )}
                                        <tbody>
                                          {item_diamonds.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item.shape}</td>
                                              <td>{item.pieces}</td>
                                              <td className="text-right">
                                                {numberWithFloat(item.weight)}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Tab>
                                    <Tab eventKey="gemstones" title="gemstones">
                                      <Table className="tabs_table mb-0">
                                        {item_gemstones.length > 0 ? (
                                          <thead>
                                            <tr>
                                              <th>{t('name')}</th>
                                              <th>{t('shape')}</th>
                                              <th>{t('pieces')}</th>
                                              <th className="text-right">
                                                {t('weightGms')}
                                              </th>
                                            </tr>
                                          </thead>
                                        ) : (
                                          <thead>
                                            <center>{t('noRecord')}</center>
                                          </thead>
                                        )}
                                        <tbody>
                                          {item_gemstones.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item.name}</td>
                                              <td>{item.shape}</td>
                                              <td>{item.pieces}</td>
                                              <td className="text-right">
                                                {numberWithFloat(item.weight)}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Tab>
                                  </Tabs>
                                </div>
                              )}
                            {
                              dealersPortal == false 
                              && (
                                <div>
                                  <h6 className="mt-2 mb-2 text gray text-left p-0">
                                    {t('deliveryNote')}
                                  </h6>
                                  <ul className="key-features-list list-unstyled pl-0 black">
                                    {this.state.deliveryPrefrences.map(
                                      (deliveryPrefrence, index) => (
                                        <React.Fragment>
                                          <li key={index}>
                                            {deliveryPrefrence.field_value ==
                                              'yes' ? (
                                              <i className="fas fa-check-circle green">
                                                &nbsp;
                                              </i>
                                            ) : (
                                              <i className="fas fa-times-circle red">
                                                &nbsp;
                                              </i>
                                            )}
                                            {deliveryPrefrence.field_name}
                                          </li>
                                        </React.Fragment>
                                      ),
                                    )}
                                  </ul>
                                  {this.state.deliveryPrefrences.map(
                                    (deliveryPrefrence, index) =>
                                      deliveryPrefrence.field_name ==
                                      'home delivery available' &&
                                      deliveryPrefrence.field_value == 'yes' && (
                                        <React.Fragment>
                                          <span>
                                            Home delivery available for this
                                            product,please enter your area pincode
                                            to check
                                          </span>
                                          <br />
                                          <Form.Group
                                            as={Row}
                                            className="input-field">
                                            <Col lg={6}>
                                              <Form.Control
                                                type="text"
                                                onChange={(e) =>
                                                  this.checkPinCode(
                                                    e.target.value,
                                                  )
                                                }
                                                placeholder="Enter area pincode"
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <span style={{ color: 'red' }}></span>
                                            </Col>
                                          </Form.Group>
                                        </React.Fragment>
                                      ),
                                  )}
                                  <span
                                    className={
                                      this.state.pinValidate == true
                                        ? 'green'
                                        : 'red'
                                    }>
                                    {this.state.message}
                                  </span>
                                </div>
                              )
                            }
                            {Object.keys(this.props.user).length > 0 &&
                              this.props.user.user_type == 0 &&
                              this.props.catalogId != '' && (
                                <div className="mt-2">
                                  <Button
                                    className="btn-lg mr-2"
                                    style={this.props.styles.primary_btn}
                                    onClick={() =>
                                      this.addRemoveProductFromCatalog(
                                        this.state.productDetail.id,
                                        this.props.catalogId,
                                        typeof this.props.catalogProducts !=
                                          'undefined' &&
                                          this.props.catalogProducts.length !=
                                          '' &&
                                          typeof this.props.catalogProducts[
                                          this.state.productDetail.id
                                          ] != 'undefined' &&
                                          this.props.catalogProducts[
                                            this.state.productDetail.id
                                          ].id != ''
                                          ? this.props.catalogProducts[
                                            this.state.productDetail.id
                                          ].id
                                          : '',
                                      )
                                    }>
                                    {typeof this.props.catalogProducts !=
                                      'undefined' &&
                                      this.props.catalogProducts != '' &&
                                      typeof this.props.catalogProducts[
                                      this.state.productDetail.id
                                      ] != 'undefined' &&
                                      this.props.catalogProducts[
                                        this.state.productDetail.id
                                      ].is_added_catalog === 1
                                      ? 'Remove from catalog'
                                      : 'Add to catalog'}
                                  </Button>
                                </div>
                              )}

                            {(Object.keys(this.props.user).length == 0 ||
                              (Object.keys(this.props.user).length > 0 &&
                                (this.props.user.user_type == 1 ||
                                  this.props.user.user_type == 2))) && (
                                <React.Fragment>
                                  <div className="mt-2">
                                    {!dealersPortal &&
                                      this.state.productDetail.disable_ordering ==
                                      0 &&
                                      this.state.productDetail
                                        .customer_stock_status == 1 &&
                                      (this.state.variations.length > 0 ? (
                                        this.state.productDetail.prebooking === '1' ? (
                                          <>
                                            <div>
                                              <Button
                                                loading={
                                                  this.state.cartButtonLoader
                                                }
                                                className="btn-lg mr-2"
                                                style={
                                                  this.props.styles.primary_btn
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  // window.location.href = `https://staging-scott-sports.ascratech.com/prebooking/prebookings?slug=${this.state.productDetail.slug}`;
                                                  window.location.href = `https://customerconnect.sportnetwork.in/prebooking/prebookings?slug=${this.state.productDetail.slug}`;

                                                  // console.log(`https://customerconnect.sportnetwork.in/prebooking/prebookings?slug=${this.state.productDetail.slug}`);
                                                }}>
                                                Pre Book Now
                                              </Button>
                                            </div>{' '}
                                          </>
                                        ) : (
                                          <>
                                            <div>
                                              <Button
                                                loading={
                                                  this.state.cartButtonLoader
                                                }
                                                className="btn-lg mr-2 mybutton1"
                                                style={
                                                  this.props.styles.primary_btn
                                                }
                                                onClick={() => {
                                                  console.log("Add to cart button clicked on product details page")
                                                  this.addRemoveProductFromCart(
                                                    this.state.productDetail.id,
                                                    0,
                                                  )
                                                }}>
                                                Add to cart
                                              </Button>
                                            </div>{' '}
                                          </>
                                        )
                                      ) : typeof this.props.cartProducts !=
                                        'undefined' &&
                                        typeof this.props.cartProducts[
                                        this.state.productDetail.id
                                        ] != 'undefined' &&
                                        this.props.cartProducts[
                                          this.state.productDetail.id
                                        ].added_into_transaction === 1 ? (
                                        <Button
                                          loading={this.state.cartButtonLoader}
                                          className="btn-lg mr-2 mybutton2"
                                          onClick={() => {
                                            console.log("i ran far")
                                            this.addRemoveProductFromCart(
                                              this.state.productDetail.id,
                                              1,
                                            )}
                                          }
                                          style={this.props.styles.primary_btn}>
                                          Added to cart
                                        </Button>
                                      ) : (
                                        <Button
                                          loading={this.state.cartButtonLoader}
                                          className="btn-lg mr-2"
                                          onClick={() =>
                                            this.addRemoveProductIntoCartFromDetail(
                                              this.state.productDetail.name,
                                              1,
                                            )
                                          }
                                          style={this.props.styles.primary_btn}>
                                          Add to cart
                                        </Button>
                                      ))}

                                    {!dealersPortal &&
                                      this.props.settings.enable_wishlist ==
                                      'Yes' &&
                                      (this.state.variations.length > 0 ? (
                                        <Button
                                          loading={this.state.cartButtonLoader}
                                          className="btn-lg mr-2"
                                          style={this.props.styles.primary_btn}
                                          onClick={() =>
                                            this.addRemoveProductFromWishlist(
                                              this.state.productDetail.id,
                                              0,
                                            )
                                          }>
                                          Add to Wishlist
                                        </Button>
                                      ) : typeof this.props.wishlistProducts !=
                                        'undefined' &&
                                        typeof this.props.wishlistProducts[
                                        this.state.productDetail.id
                                        ] != 'undefined' &&
                                        this.props.wishlistProducts[
                                          this.state.productDetail.id
                                        ].added_into_transaction === 1 ? (
                                        <Button
                                          loading={
                                            this.state.wishlistButtonLoader
                                          }
                                          className="btn-lg mr-2"
                                          onClick={() =>
                                            this.addRemoveProductFromWishlist(
                                              this.state.productDetail.id,
                                              1,
                                            )
                                          }
                                          style={this.props.styles.primary_btn}>
                                          Added to Wishlist
                                        </Button>
                                      ) : (
                                        <Button
                                          loading={
                                            this.state.wishlistButtonLoader
                                          }
                                          className="btn-lg mr-2"
                                          onClick={() =>
                                            this.addRemoveProductFromWishlist(
                                              this.state.productDetail.id,
                                              0,
                                            )
                                          }
                                          style={this.props.styles.primary_btn}>
                                          Add to Wishlist
                                        </Button>
                                      ))}
                                    <span className="red">
                                      {this.state.errors != ''
                                        ? this.state.errors['carts[item_id]']
                                        : ''}
                                    </span>
                                  </div>
                                  <div className="mt-2">
                                    <span className="red">
                                      {this.state.errors != ''
                                        ? this.state.errors['carts[item_id]']
                                        : ''}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}

                            {(Object.keys(this.props.user).length == 0 ||
                              (Object.keys(this.props.user).length > 0 &&
                                (this.props.user.user_type == 1 ||
                                  this.props.user.user_type == 2))) && (
                                <React.Fragment>
                                  <div className="mt-2">
                                    {dealersPortal &&
                                      this.state.productDetail.disable_ordering ==
                                      0 &&
                                      this.state.inventories.length > 0 &&
                                      this.state.productDetail
                                        .dealer_stock_status == 1 &&
                                      this.state.sales_login == 1 && (
                                        <Button
                                          loading={this.state.cartButtonLoader}
                                          className="btn-lg mr-2"
                                          onClick={() =>
                                            this.setBulkCartFromdata(
                                              this.state.productDetail.id,
                                            )
                                          }
                                          style={this.props.styles.primary_btn}>
                                          Add to cart
                                        </Button>
                                      )}
                                  </div>

                                  {/* <div  id="razorpay-affordability-widget"> </div> */}

                                  <p></p>
                                  {this.state.productDetail.videos.length > 0 ? (
                                    <h6 className="mb-0 pr-3 bold text-nowrap">
                                      Point Of View:{' '}
                                    </h6>
                                  ) : (
                                    <h6></h6>
                                  )}
                                  <p></p>
                                </React.Fragment>
                              )}
                            <Row className="pd7">
                              {this.state.productDetail.length != 0 &&
                                this.state.productDetail.videos.map(
                                  (video, index) => (
                                    <Col xs={3}>
                                      <div className="video_sec" key={index}>
                                        <a
                                          data-toggle="modal"
                                          data-target={'#videoModal' + index}>
                                          <img
                                            src={Im[index]}
                                            className="img-fluid"
                                          />
                                          <div className="video_action">
                                            <i className="far fa-play-circle"></i>
                                            <p className="mb-0">Watch here</p>
                                          </div>
                                        </a>
                                      </div>

                                      <div
                                        className="modal fade"
                                        id={'videoModal' + index}
                                        tabindex="-1"
                                        role="dialog"
                                        aria-hidden="true">
                                        <div
                                          className="modal-dialog modal-dialog-centered modal-lg"
                                          role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <iframe
                                                width="100%"
                                                height="400"
                                                src={
                                                  video.video_url_1 +
                                                  '?autoplay=1'
                                                }
                                                frameborder="0"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )}
                            </Row>
                            <div
                              className="mt-4"
                              id="razorpay-affordability-widget">
                              {' '}
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {/*<SimilarProduct recommendation={this.state.recommendation}/>*/}
                  </Container>

                  <ProductDetailsTabs
                    specifications={this.state.specifications}
                    description={this.state.productDetail.description}
                    technologies={this.state.technologies}
                    itemId={this.state.productDetail.id}
                    whyToBuy={this.state.whyToBuy}
                    geometryAttachment={this.state.geometry_attachment}
                  />

                  <Container>
                    <Row>
                      <Col sm={12}>
                        <hr />
                        <p
                          className="text-center mb-0"
                          style={{ color: '#ccc', fontSize: '12px' }}>
                          {t('reserveRights')}.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
              <SizeChartModal sizeChart={this.state.productDetail.size_chart} />
              <FindSizeModal />
              {compareProducts.length > 0 && (
                <CompareModal
                  removeAllCompareItem={() => this.removeAllCompareItem()}
                  removeCompareItem={(compare_id) =>
                    this.removeCompareItem(compare_id)
                  }
                  products={compareProducts}
                />
              )}
              <Modal show={this.state.showVariationModal}>
                <Modal.Header>
                  <Modal.Title>{this.state.productName}</Modal.Title>
                  <a
                    onClick={() => this.setState({ showVariationModal: false })}
                    className="action_icon">
                    <i className="far fa-window-close"></i>
                  </a>
                </Modal.Header>
                <Modal.Body>
                  {!dealersPortal && (
                    <ProductVariations
                      variations={this.state.variations}
                      selectvariation={(
                        name,
                        value,
                        changeImage,
                        variationName,
                      ) =>
                        this.selectvariation(
                          name,
                          value,
                          changeImage,
                          variationName,
                        )
                      }
                      selectedVariations={this.state.selectedVariations}
                      settings={this.props.settings}
                      quantity={this.state.quantity}
                      popupFor={this.state.popupFor}
                      changeQuantity={(quantity) =>
                        this.changeQuantity(quantity)
                      }
                      setRemark={(remark) => this.setState({ remark: remark })}
                      t={this.props.t}
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {!dealersPortal && (
                    <React.Fragment>
                      {this.props.settings.enable_wishlist == 'Yes' &&
                        this.state.popupFor == 0 && (
                          <TransactionButton
                            loading={this.state.wishlistButtonLoader}
                            variant="secondary"
                            className="btn-lg mr-2 transparent"
                            onClick={() =>
                              this.addRemoveProductFromWishlist(
                                this.state.productDetail.id,
                                0,
                              )
                            }>
                            Add to Wishlist
                          </TransactionButton>
                        )}
                      {this.state.popupFor == 1 && (
                        <TransactionButton
                          loading={this.state.cartButtonLoader}
                          variant="primary"
                          className="btn-lg mybuttonthree"
                          onClick={() => {
                            console.log("i ran second")
                            this.addRemoveProductFromCart(
                              this.state.productDetail.id,
                              0,
                            )
                          }}>
                          {t('Add to cart')}
                        </TransactionButton>
                      )}
                    </React.Fragment>
                  )}

                  {dealersPortal && this.state.sales_login == 1 && (
                    <React.Fragment>
                      <TransactionButton
                        loading={this.state.cartButtonLoader}
                        variant="primary"
                        className="btn-lg "
                        onClick={() =>
                          this.setBulkCartFromdata(this.state.productId)
                        }>
                        {t('Add to cart')}
                      </TransactionButton>
                    </React.Fragment>
                  )}
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          )}
          <Loader loaderState={this.state.loader} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    settings: state.settings.settings,
    cartProducts: state.carts.products,
    wishlistProducts: state.wishlists.products,
    catalogProducts: state.catalogs.products,
    catalogId: state.catalogs.id,
    catalogSymbol: state.catalogs.symbol,
    catalogName: state.catalogs.name,
    styles: state.styles.styles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {
      dispatch({ type: 'SET_CART_COUNT', payload: count });
    },
    setCartProducts: (products) => {
      dispatch({ type: 'SET_CART_PRODUCTS', payload: products });
    },
    setWishListCount: (count) => {
      dispatch({ type: 'SET_WISHLIST_COUNT', payload: count });
    },
    setWishListProducts: (products) => {
      dispatch({ type: 'SET_WISHLIST_PRODUCTS', payload: products });
    },
    setCatalogCount: (count) => {
      dispatch({ type: 'SET_CATALOG_COUNT', payload: count });
    },
    setCatalogProducts: (products) => {
      dispatch({ type: 'SET_CATALOG_PRODUCTS', payload: products });
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetails),
);


