import React from 'react';
import { getOrderDetail, updateSuccessPayment, createOrder, updateFailedPayment } from  '../../../../core/helpers/ServicesHelper';
import { razorPayLiveKey } from  '../../../../core/helpers/ConfigHelper';
import {Container, Button,Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WaitingImg from '../../../assets/images/icons/timer.gif'; 
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import visa from '../../../assets/images/icons/visa.png';
import mastercard from '../../../assets/images/icons/mastercard.png';
import paypal from '../../../assets/images/icons/paypal.png';

class Payment extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      orderDetail:[],
      paymentStatus:'',
      redirectToOrder:false,
    }
  }

  async componentDidMount(){
    await this.makePayment();
  }

  async makePayment() {
    var orderId = this.props.match.params.orderId;
    var orderDetail = await getOrderDetail(orderId);
    if (orderDetail.data.status == 'success') {
      if(orderDetail.data.data.payment_status == 'waiting for payment' || orderDetail.data.data.payment_status == 'failed'){
        await this.setState({orderDetail:orderDetail.data.data})  
        var formdata = [];
        formdata['id'] = orderDetail.data.data.id
        var order_data = await createOrder(formdata);
        if(order_data.data.status == 'success'){
          this.openCheckout(orderDetail.data.data,order_data.data.data);
        }
      }
      else{
        this.props.history.push('/order-detail/'+orderDetail.data.data.id);   
      }
    }
  }

  async updateSuccessPayment(id,formdata,props){
    var response = await updateSuccessPayment(id,formdata);
    if(response.data.status == 'success'){
      props.history.push('/order-detail/'+id+'/1');
    }
  }

  async updateFailedPayment(id,formdata,props){
    var response = await updateFailedPayment(id,formdata);
  }

  openCheckout(order,order_data) {
    var props = this.props;
    let options = {
      "key":razorPayLiveKey,
      "name": "Sport Networks",
      "order_id":order_data.id,
      'payment_capture':1,
      "description": "Purchase "+order.products.length+' items',
      "image": this.props.settings.image_url+'/application_images/original/'+this.props.settings.razorpay_payment_image,
      "handler": async function (response){
        var formdata = [];
        //  console.log(response)
        formdata['success[order_no]'] = order.id;
        formdata['success[payment_transaction_id]'] = response.razorpay_payment_id;
        formdata['success[payment_order_id]'] = response.razorpay_order_id;
        formdata['success[payment_signature]'] = response.razorpay_signature;
        var paymentObj = new Payment;
        paymentObj.updateSuccessPayment(order.id,formdata,props);
      },
      "prefill": {
        "name": this.props.user.name,
        "email": this.props.user.email_id
      },
      "theme": {
        "color": "#e74021"
      }
    };

    let rzp = new window.Razorpay(options);
    rzp.on('payment.failed', function (response){
      var formdata = [];
      formdata['failed[order_no]'] = order.id;
      formdata['failed[payment_transaction_id]'] = response.error.metadata.payment_id;
      formdata['failed[payment_order_id]'] = response.error.metadata.order_id;
      var paymentObj = new Payment;
      var response = paymentObj.updateFailedPayment(order.id,formdata,props);

    });
    rzp.open();
  }

  render () {
    const { t } = this.props;
    var d = new Date();
    var currentYear = d.getFullYear();
    return (
      <div className="main">
        <div className="container">
          <div className="col-sm-8 mx-auto mt-5">
            <div className="text-center mb-4">
              <img src={WaitingImg} className="img-fluid empty_img" />
            </div>
            <div className="text-center">
              <h4 className="black bold">Waiting for Payment</h4>
              <p>Please wait for the payment page to complete your purchase.</p>
              <p>If you have done payment and money has been deducted from your account, please check <Link to={'/order-detail/'+this.props.match.params.orderId+'/1'}>order</Link>.</p>
              <p>You will get your money back within 3-7 days in your bank account somecases when amount deducted but order not approved.</p>
              <Link to={'#'}>
                <Button onClick={() => this.makePayment()} style={this.props.styles.secondary_btn}>Retry</Button>
              </Link>
            </div>
          </div>       
        </div>
        <Container>
          <Row>
            <Col xs={12} className="text-center py-3">
              <div className="payment_links">
                <span><img src={visa} /></span>
                <span><img src={mastercard} /></span>
                <span><img src={paypal} /></span>
              </div>
              <hr/>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    settings:state.settings.settings,
    styles: state.styles.styles,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(Payment));



