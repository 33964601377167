import React, { Component } from 'react';
import cookie from 'react-cookies';
import { getSettings, getCartListing, placeOrder, getOrderSummery } from '../../helpers/ServicesHelper';

import { toast } from 'react-toastify';
import { checkValidHash, mobile, getMobileToast } from  '../../helpers/OperationsHelper';
import { submitOrder } from  '../../helpers/TransactionsHelper';


class CorePreparingCheckout extends React.Component {
  constructor(props){
		super(props);
		this.state={
			loader: true,
			carts: [],
			orderSummery: [],
      redirectUrl:''
		}
	}

  async componentDidMount(){
    window.history.pushState(null, null, window.location.pathname);
   await this.checkoutIdentityfication();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.settings != this.props.settings) {
      await this.checkoutIdentityfication();
    }
  }
  
  async checkoutIdentityfication() {
    if (typeof this.props.settings.is_checkout_enabled != 'undefined') {
      if (this.props.settings.is_checkout_enabled == 'No') {
        await this.getCartListing();
        await this.getOrderSummery();
        if (cookie.load('authToken') != '') {
          this.placeOrder();
        } else {
          this.props.history.push('/login');
        }
      } else {
        this.props.history.push('/checkout');
      }  
    }
  }


	async getCartListing(){
    var carts = await getCartListing();
    if (carts.data.status == 'success') {
      this.setState({carts:carts.data.data});
    }
  }

  async getOrderSummery(where='') {
    var orderSummery = await getOrderSummery(where);
    if (orderSummery.data.status == 'success') {
      this.setState({orderSummery:orderSummery.data.data});
    } 
  }

  async placeOrder(orderType=0){
    if (Object.keys(this.props.user).length > 0 && this.props.user.id != '' && this.props.user.user_type != '') {
      var response = await submitOrder(this.state.carts, 0, this.props.user.id, this.props.user.user_type); 
      if (response.data.status == 'success') {
        await checkValidHash();
        this.props.history.push('/order-detail/'+response.data.data.id+'/1');
        this.props.setCartCount(0);
        this.props.setCartProducts({});
      } else {
        if ( typeof response.data.errors['orders[shipping_address_id]'] != 'undefined' && response.data.errors['orders[shipping_address_id]'] != '') {
          var message = response.data.errors['orders[shipping_address_id]'];
        } else {
          var message = 'Your product is out of stock please check your cart.';
        }
        toast.error(message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }); 
      }
    }else{
      this.props.history.push('/login');
    }
  }
}

export default CorePreparingCheckout;