import MasterCatalog from './MasterCatalog';
import { saveCatalog, getCatalogs, getCatalogProducts } from '../../helpers/ServicesHelper';

class CoreCatalogListing extends MasterCatalog {
  constructor(props) {
    super(props);
    this.state = {
      catalogs: [],
      loader: true,
    }
  }

  async componentDidMount() {
    await this.getCatalogs();
    this.setState({ loader: false });
  }

  async getCatalogs() {
    var catalogs = await getCatalogs();
    console.log(catalogs);
    if (catalogs.data.status === 'success') {
      this.setState({ catalogs: catalogs.data.data });
    }
  }

  async getCatalogProducts(catalogId) {
    var catalogProducts = await getCatalogProducts(catalogId);
    // console.log("catalog-products - ", + catalogProducts);
    if (catalogProducts.data.status == 'success') {
      await this.props.setCatalogCount(Object.keys(catalogProducts.data.data).length);
      await this.props.setCatalogProducts(catalogProducts.data.data);
    } else {
      console.log(catalogProducts.data.status);
    }
  }

}

export default CoreCatalogListing;