import React from 'react';
import {Container, Row, Col, Button, Table, Tabs, Tab, Card, Form,} from 'react-bootstrap';
import LoadingButton from 'react-bootstrap-button-loader';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';

import CoreOrderSummery from '../../../../core/components/transactions/CoreOrderSummery';
import loader from '../../../assets/loader/circle-loader.gif';
import OrderSummeryContent from './OrderSummeryContent';
import SideLoader from '../../../components/SideLoader';
import { dealersPortal } from  '../../../../core/helpers/OperationsHelper';

class OrderSummery extends CoreOrderSummery { 
  
  onClickButton = async (orderType) => {
    if (dealersPortal == true) {
      this.placeOrder(orderType);
    } else {
      if(this.props.order==true){
        this.placeOrder(orderType);
      } else {
        this.props.history.push('/seller-selection');
      }
    }
  }

  render() {

    const {carts, orderSummery} = this.state;
    //  console.log(orderSummery);
    const { t } = this.props;
    //  console.log(this.props);
    //  console.log(this.props.placeOrder);
    return (      
      <Col lg={4}>
        {(this.props.placeOrder) && (
          <div className="text-center mb-3">
            <LoadingButton 
              loading={this.state.placeOrderButtonLoader} 
              className="btn btn-md d-block w-100 " 
              style={this.props.styles.primary_btn} 
              onClick={()=>this.onClickButton(0)}>{t('placeOrder')}
            </LoadingButton>
          </div>
        )}
        {(cookie.load('accessToken')) && (
          <div className="text-center mb-3">
            <LoadingButton 
              loading={this.state.placeReplacementOrderButtonLoader} 
              className="btn btn-md d-block w-100 " 
              style={this.props.styles.primary_btn} 
              onClick={()=>this.onClickButton(3)}>{t('placeReplacementOrder')}
            </LoadingButton>
          </div>
        )}
        <Card className="shadow-sm">
          <Card.Body>                 
            {(dealersPortal == false && this.props.applyCouponCode == true && cookie.load('authToken')) && (
              <React.Fragment>
                <div id="coupon_sec">
                  <div className="custom_collapse">
                    <div className="custom_toggle arrow">
                      <a className="card-link text-uppercase black collapsed" data-toggle="collapse" href="#coupon">
                        {t('code')}
                      </a>
                    </div>
                    <div id="coupon" className="collapse" data-parent="#coupon_sec">
                      <form className="pt-3 fields-group-md">
                        <Form.Group>
                          {/*<Form.Label>Enter discount code</Form.Label>*/}
                          <Form.Control onChange={(e)=>this.setState({discountCoupanCode:e.target.value})} type="text" />                           
                          <span className="red">{this.state.errors['discounts[coupon_code]']}</span>
                        </Form.Group>
                      </form>
                      <Button 
                        onClick={()=>this.applyCouponCode()} 
                        className="btn btn-sm transparent d-block w-100" 
                        style={this.props.styles.secondary_btn}>{t('applyCoupon')}
                      </Button>
                    </div>
                  </div>
                </div>    
                <hr className="mt-3 mb-3" />
              </React.Fragment>
            )}

            {(dealersPortal == false && cookie.load('authToken') && this.props.useStoreCredit == true) && (
              <React.Fragment>
                <div id="store_credit">
                  <div className="custom_collapse">
                    <div className="custom_toggle arrow">
                      <a className="card-link text-uppercase black collapsed" data-toggle="collapse" href="#store_credit_id">
                        Use store credit
                      </a>
                    </div>
                    <div id="store_credit_id" className="collapse" data-parent="#store_credit">
                      <form className="pt-3 fields-group-md">
                      <span>{t('creditBalance')}: {this.state.storeCreditBalance}</span>
                        <Form.Group>
                          {/*<Form.Label>Enter discount code</Form.Label>*/}
                          <Form.Control onChange={(e)=>this.setState({storeCredit:e.target.value})} type="text" placeholder="Enter credit amount" />                           
                          <span className="red">{this.state.errors['store_credits[debit_amount]']}</span>
                          {(this.state.success)&&<span className="green">{'Store credit applied'}</span>}
                        </Form.Group>
                      </form>
                      <Button onClick={()=>this.applyStoreCredit()}className="btn btn-sm transparent d-block w-100" style={this.props.styles.secondary_btn}>Use store credit</Button>
                    </div>
                  </div>
                </div>    
                <hr className="mt-3 mb-3" />
              </React.Fragment>
            )}    
            
            {dealersPortal == false && orderSummery.coupon_code != '' && this.props.applyCouponCode == true && (
              <React.Fragment>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className="mb-0 green">{t('couponApplied')} :</p>
                  </Col>
                  <Col xs={6} className="text-right">
                    <a onClick={() => this.removeCouponCode()} className="mb-0 red" href="javascrip:void">
                      {t('remove')}
                    </a>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className="mb-0">{orderSummery.coupon_code}</p>
                  </Col>
                  
                </Row>
                <hr className="mt-3 mb-3" />
              </React.Fragment>
            )}
    
            <h2 className="small_heading bold text-uppercase">{t('orderSummary')}</h2>
            {
              Object.keys(orderSummery)?.length > 0 
              ? (
                <OrderSummeryContent 
                  orderSummery={orderSummery}  
                  currency={orderSummery.currency} 
                />
              ) : <SideLoader height="200" width="300"/>
            }
                                                      
            <div className="checkout_list cart_table">
              {this.state.carts.length > 0 ? (
                this.state.carts.map((cart, index) => (
                  <div key={index} className="checkout_list_item row cart_item">
                    <div className="col-4">
                      {(typeof this.state.images[cart.id] != 'undefined' && this.state.images[cart.id] != "") ?
                        <img src={this.state.images[cart.id]} className="product_img img-fluid" /> :
                        <img src={loader} className="product_img img-fluid" /> 
                      }
                    </div>
                    <div className="col-8 product_item_details">
                      <p className="mb-0 product_item_name">{cart.item_details.name}</p>
                      <Table borderless width="100%" className="mb-0 mt-2">
                        <tbody>
                          <tr className="item_options">
                            <td width="35%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">{t('model')}</p></td> 
                            <td width="65%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">: {cart.item_details.item_code}</p></td> 
                          </tr>
                          {cart.product_types.currency == 1 && (
                            <tr className="item_options">
                              <td width="35%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">{cart.product_types.field_name}</p></td>  
                              <td width="65%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">: <i className="fal fa-rupee-sign"></i> {cart.product_types.field_value - ( (cart.product_types.field_value * cart.dealer_discount) / 100 )}</p></td>  
                            </tr>
                          )}
                          {cart.variations.map((variation, key) => (
                            <tr key={key} className="item_options">
                              <td width="35%" className="px-0 py-0 pb-1"><p className="mb-0 text-capitalize">{variation.variation_name}</p></td>
                              <td width="65%" className="px-0 py-0 pb-1"><p className="mb-0 text-capitalize"> : {variation.variation_value}</p></td>
                            </tr>
                          ))}
                          <tr className="item_options">
                            <td width="35%" className="px-0 py-0 pb-1"><p className="">{t('qty')}</p></td>                       
                            <td width="65%" className="px-0 py-0 pb-1"><p className="">: {cart.quantity}</p></td>                        
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))
              ):<SideLoader height="160" width="300"/>}
            </div>
          </Card.Body>
        </Card>
      </Col>
      
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    user: state.users.user,
    styles:state.styles.styles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {dispatch({ type: 'SET_CART_COUNT', payload: count})},
    setCartProducts: (products) => {dispatch({ type: 'SET_CART_PRODUCTS', payload: products})},
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderSummery));