import React from 'react';
import cookie from 'react-cookies';
import { Redirect } from 'react-router';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {getAddresses,getStoreCreditBalance} from '../../helpers/ServicesHelper'
import {mobile, dealersPortal} from '../../helpers/OperationsHelper'
import { toast } from 'react-toastify';

class CoreProfile extends React.Component {
  constructor(props){
		super(props);
    this.state={'billindAddress':[],storeCreditBalance:0}
	}

	componentDidMount(){
		window.history.pushState(null, null, window.location.pathname);
		this.getBillingAddress();
		this.getStoreCreditBalance();
	}

	async getBillingAddress(){
		var where = 'where[fl_user_addresses.user_id]='+this.props.user.id+'&where[fl_user_addresses.type]=billing'
		var response = await getAddresses(where);
		//  console.log(response.data)
		if(response.data.status == 'success'){
			if(response.data.data.length > 0)
				this.setState({billindAddress:response.data.data[0]})
		}
	}

	async getStoreCreditBalance(){
		//  console.log(this.props.user.id);
		var response = await getStoreCreditBalance(this.props.user.id);
		if(response.data.status == 'success'){
			//  console.log(response.data.data)
			this.setState({storeCreditBalance:response.data.data})
		}
	}

}

export default CoreProfile;