import React from 'react';
import {Container, Row, Col, Button, Table, Tabs, Tab, Card, Form,} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { numberWithFloat, dealersPortal } from '../../../../core/helpers/OperationsHelper';

class OrderSummeryContent extends React.Component {

	render(){
    //  console.log(this.props.currency);

    const { t } = this.props;
		return (
			<React.Fragment>
        <Row className="mb-1">
          <Col xs={6}>
            <p className="mb-0">{t('subTotal')} ({t('excludeTax')})</p>
          </Col>
          <Col xs={6} className="text-right">
            <p className="mb-0">
              {this.props.currency == 1 && this.props.orderSummery.subtotal_exclude_tax > 0 && (
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
              )}
              <span>{ this.props.orderSummery.subtotal_exclude_tax > 0 ? numberWithFloat(this.props.orderSummery.subtotal_exclude_tax) : '-'} </span>
              {this.props.currency == 0 && this.props.orderSummery.subtotal_exclude_tax > 0 && (
                <span>{this.props.unit}</span>
              )}
            </p>
          </Col>
        </Row>
        {(this.props.orderSummery.tax > 0)&&<Row className="mb-1">
          <Col xs={6}>
            <p className="mb-0">Tax</p>
          </Col>
          <Col xs={6} className="text-right">
            <p className="mb-0">
              {this.props.currency == 1 && this.props.orderSummery.tax > 0 && (
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
              )}
              <span>{ this.props.orderSummery.tax > 0 ? numberWithFloat(this.props.orderSummery.tax) : '-'} </span>
              {this.props.currency == 0 && this.props.orderSummery.tax > 0 && (
                <span>{this.props.unit}</span>
              )}
            </p>
          </Col>
        </Row>
      }
        <Row className="mb-1">
          <Col xs={6}>
            <p className="mb-0">{t('subTotal')} ({t('includeTax')})</p>
          </Col>
          <Col xs={6} className="text-right">
            <p className="mb-0">
              {this.props.currency == 1 && this.props.orderSummery.subtotal_include_tax > 0 && (
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
              )}
              <span>{this.props.orderSummery.subtotal_include_tax > 0 ? numberWithFloat(this.props.orderSummery.subtotal_include_tax) : '-'} </span>
              {this.props.currency == 0 && this.props.orderSummery.subtotal_include_tax > 0 && (
                <span>{this.props.unit}</span>
              )}
            </p>
          </Col>
        </Row>
        {(dealersPortal == false && this.props.orderSummery.assembly_charges > 0) &&
          <Row className="mb-1">
            <Col xs={6}>
              <p className="mb-0">{t('assemblyCharges')}</p>
            </Col>
            <Col xs={6} className="text-right">
              <p className="mb-0">
                {this.props.currency == 1 && this.props.orderSummery.assembly_charges > 0 && (
                  <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                )}
                <span>{this.props.orderSummery.assembly_charges > 0 ? numberWithFloat(this.props.orderSummery.assembly_charges) : '-'} </span>
                {this.props.currency == 0 && this.props.orderSummery.assembly_charges > 0 && (
                  <span>{this.props.unit}</span>
                )}
              </p>
            </Col>
          </Row>
        }
        
        {/*{(dealersPortal == false && this.props.orderSummery.coupon_amount > 0) &&
          <React.Fragment>
            <Row className="mb-1">
              <Col xs={6}>
                <p className="mb-0">{t('couponAmount')}</p>
              </Col>
              <Col xs={6} className="text-right">
                <p className="mb-0">
                  <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                  <span>{this.props.orderSummery.coupon_amount}</span>
                </p>
              </Col>
            </Row>
          </React.Fragment>  
        }*/}
        <hr className="mt-1 mb-1" />
        <Row>
          <Col xs={6}>
            <p className="mb-0 bold">{t('orderTotal')}</p>
          </Col>
          <Col xs={6} className="text-right">
            <p className="mb-0 bold">
              {this.props.currency == 1 && this.props.orderSummery.total > 0 && (
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
              )}
              <span>{(this.props.currency == 1)? numberWithFloat(this.props.orderSummery.total):numberWithFloat(this.props.orderSummery.total)} </span>
              {this.props.currency == 0 && this.props.orderSummery.total > 0 && (
                <span>{this.props.unit}</span>
              )}
            </p>
          </Col>
        </Row>
        {(this.props.orderSummery.shipping_charges > 0) &&
          <Row className="mb-1">
            <Col xs={6}>
              <p className="mb-0">{t('shipping')}</p>
            </Col>
            <Col xs={6} className="text-right">
              <p className="mb-0">
                {this.props.currency == 1 && this.props.orderSummery.shipping_charges > 0 && (
                  <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                )}
                <span>{this.props.orderSummery.shipping_charges > 0 ? numberWithFloat(this.props.orderSummery.shipping_charges) : '-'} </span>
                {this.props.currency == 0 && this.props.orderSummery.shipping_charges > 0 && (
                  <span>{this.props.unit}</span>
                )}
              </p>
            </Col>
          </Row>
        }
        {(dealersPortal == false && this.props.orderSummery.store_credit > 0) && (
          <Row className="mb-1">
            <Col xs={6}>
              <p className="mb-0">{t('storeCredit')}</p>
            </Col>
            <Col xs={6} className="text-right">
              <p className="mb-0">
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                <span>{numberWithFloat(this.props.orderSummery.store_credit)}</span>
              </p>
            </Col>
          </Row>
        )}
        {(dealersPortal == false && this.props.orderSummery.coupon_amount > 0) && (
          <Row className="mb-1">
            <Col xs={6}>
              <p className="mb-0">{t('couponAmount')}</p>
            </Col>
            <Col xs={6} className="text-right">
              <p className="mb-0">
                <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                <span>{numberWithFloat(this.props.orderSummery.coupon_amount)}</span>
              </p>
            </Col>
          </Row>
        )}
        {(dealersPortal == false) && (
          <React.Fragment>
            <hr className="mt-1 mb-1" />
            <Row>
              <Col xs={6}>
                <p className="mb-0 bold">{t('total')}</p>
              </Col>
              <Col xs={6} className="text-right">
                <p className="mb-0 bold">
                  {this.props.currency == 1 && this.props.orderSummery.total > 0 && (
                    <i className="fal fa-rupee-sign" style={{fontSize:'12px',paddingRight:'2px'}}></i>  
                  )}
                  <span>{(this.props.currency == 1)? numberWithFloat(this.props.orderSummery.amount_to_be_paid) : numberWithFloat(this.props.orderSummery.amount_to_be_paid)} </span>
                  {this.props.currency == 0 && this.props.orderSummery.amount_to_be_paid > 0 && (
                    <span>{this.props.unit}</span>
                  )}
                </p>
              </Col>
            </Row>
          </React.Fragment>
        )}
            
      </React.Fragment>
			
		);
	}
}


export default withTranslation()(OrderSummeryContent);