import React from 'react';
import {Container, Row, Col, Button, Table,Form} from 'react-bootstrap';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';

import MultipleVariationSelection from './MultipleVariationSelection';
import SingleVariationSelection from './SingleVariationSelection';

class ProductVariations extends React.Component { 
  render() {   
    const { t, variations,settings,popupFor } = this.props; 
    // console.log(this.props.selectedVariationKey);
    // console.log(this.props.variationSkuCombinations);
    // console.log(this.props.selectVariationSkuCode);
    return (
      <React.Fragment>
      {(this.props.variations.length > 0 && settings.remarkPopupEnable != 'Yes')&&(
        this.props.variations.map((variation,variation_key)=>(
          <div key={variation_key} className="product_sizes">
            <div className="mb-2 align-items-center">
              <h6 className="mb-1 pr-3 bold"><span className="bold">{t('select')} {variation.name} </span> : <span>{this.props.selectedVariationsNames[variation.name]}</span></h6>
              {this.props.variations.length > 1 ? 
                <MultipleVariationSelection 
                  variation={variation}
                  selectedVariationKey={this.props.selectedVariationKey}
                  variationSkuCombinations={this.props.variationSkuCombinations}
                  selectVariationSkuCode={this.props.selectVariationSkuCode}
                  selectvariation={(name, sku_no, popup, value) => this.props.selectvariation(name, sku_no, popup, value)}
                  selectedVariations={this.props.selectedVariations}
                  settings={this.props.settings}
                  changeImage={0}
                /> :
                <SingleVariationSelection 
                  variation={variation}
                  selectedVariationKey={this.props.selectedVariationKey}
                  variationSkuCombinations={this.props.variationSkuCombinations}
                  selectVariationSkuCode={this.props.selectVariationSkuCode}
                  selectvariation={(name, sku_no, popup, value) => this.props.selectvariation(name, sku_no, popup, value)}
                  selectedVariations={this.props.selectedVariations}
                  settings={this.props.settings}
                  changeImage={0}
                />
              } 
            </div>
          </div>
      )))}
      {(typeof this.props.settings.quantity_selector != 'undefined' && this.props.settings.quantity_selector === 'Yes'  && settings.remarkPopupEnable != 'Yes') && (
        <div className="d-flex align-items-center">
          <h6 className="mb-0 pr-3 bold text-nowrap">{t('select')} {t('quantity')} :</h6>
          <div>
            <div className="quantity_input_div">
              <button className="quantity_btn"  onClick={()=>this.props.changeQuantity(parseInt(this.props.quantity) - 1)}>
                <i className="fal fa-minus"></i>
              </button>
              <input type="text" className="quantity_input" value={this.props.quantity} />
              <button className="quantity_btn" onClick={()=>this.props.changeQuantity(parseInt(this.props.quantity) + 1)}>
                <i className="fal fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      )}
      {(settings.remarkPopupEnable == 'Yes')&&
        <div  className="product_sizes">
          <div className="d-flex mb-2 align-items-center w-100">                      
            <Form.Group className="w-100">
              <Form.Label column lg="12">{t('pleaseEnterRemark')}</Form.Label>
              <Col lg="12">
                <Form.Control type="text" placeHolder="Enter Remark" className="mb-1" onChange={(e)=>this.props.setRemark(e.target.value)}/> 
              </Col>                 
            </Form.Group>
          </div>
        </div>
      }
      </React.Fragment>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings
  }
}


export default withTranslation()(connect(mapStateToProps, null)(ProductVariations));